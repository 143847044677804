import React from "react";

import * as styles from "./IntroduceCard.module.scss";

const organizationIntroduceData = [
  {
    title: "테크",
    icon: "bg_tech.png",
    color: "#e1e1e1",
    tags: [
      "디스커버리개발",
      "코어시스템",
      "트랜잭션개발",
      "플랫폼엔지니어링",
      "콘텐츠서비스개발",
      "커머스서비스개발",
      "모바일앱개발",
    ],
  },
  {
    title: "데이터/AI",
    icon: "bg_data.png",
    color: "#cdf5cb",
    tags: ["데이터플랫폼", "AI/추천엔진"],
  },
  {
    title: "프로덕트",
    icon: "bg_product.png",
    color: "#ff9fb2",
    tags: ["디지털콘텐츠", "O2O플랫폼", "커머스프로덕트"],
  },
  {
    title: "디자인",
    icon: "bg_design.png",
    color: "#f4ebb8",
    tags: ["UI/UX 디자인", "커머스 디자인"],
  },
  {
    title: "e커머스",
    icon: "bg_commerce.png",
    color: "#f2d2d7",
    tags: ["퍼포먼스마케팅", "온사이트마케팅", "미디어커머스", "유저그로스"],
  },
  {
    title: "스태프",
    icon: "bg_staff.png",
    color: "#6255d3",
    tags: ["서비스 전략", "경영 관리", "PMO 전략", "IT 전략", "TPM"],
  },
];

const IntroduceCard = () => {
  return (
    <section className={styles.container}>
      {organizationIntroduceData.map((data) => (
        <article
          key={`올리브영 조직 소개 ${data.title}`}
          className={styles.wrapper}
          style={{ backgroundColor: `${data.color}` }}
        >
          <img
            className={styles.icon}
            alt={`올리브영 테크 블로그 조직 소개 ${data.title}`}
            src={`/assets/background/${data.icon}`}
          />
          <div className={styles.item}>
            <p className={styles.title}>{data.title}</p>
            {data?.tags.map((tag) => (
              <div
                className={styles.tags}
                key={`올리브영 조직 소개 ${data.title} ${tag}`}
              >
                {tag}
              </div>
            ))}
          </div>
        </article>
      ))}
    </section>
  );
};

export default IntroduceCard;

import React from "react";

import Seo from "@components/Seo";
import Layout from "@components/Layout";

import IntroduceModule from "@modules/Introduce";

const IntroducePage = () => (
  <Layout>
    <Seo
      title="팀 소개"
      description="올리브영 Tech 조직과 팀을 소개합니다. 테크, 데이터/AI, 프로덕트, 디자인, e커머스, 스태프 조직 등 올리브영 Tech가 하는 일과 조직을 알아보아요"
      url="/introduce"
    />
    <IntroduceModule />
  </Layout>
);

export default IntroducePage;

import React, { useState } from "react";
import * as styles from "./TeamCard.module.scss";
import Category, { CategoryType } from "@components/Category";

enum TEAM {
  ALL,
  ECOMMERCE,
  PRODUCT,
  TECH,
  DESIGN,
  DATA,
  STAFF,
}

interface TeamCardType {
  icon?: string;
  title?: string;
  subtitle?: string;
  description?: string[];
  tags?: string[];
}

const team = [
  {
    type: TEAM.PRODUCT,
    title: "커머스프로덕트",
    icon: "platform_product.png",
    description: [
      "전자상거래에서의 가장 본연의 코어 플랫폼 기능입니다.",
      "고객들이 주문부터, 배송, 클레임까지, 고객의 주문 흐름 여정에서 불편함을 덜고 완결성 있는 서비스를 제공합니다.",
    ],
    tags: ["고객중심", "e-commerce", "백엔드"],
  },
  {
    type: TEAM.PRODUCT,
    title: "디지털콘텐츠",
    icon: "service.png",
    description: [
      "H&B 대표 버티컬 플랫폼인 올리브영에서 사용자들이 어떻게 하면 조금 더 쉽고 재미있게 시간을 보내고 상품을 탐색할 수 있을지 고민합니다. 올리브영 회원가입부터 상품을 탐색하고, 구매를 결정하는 과정에서 필요한 서비스들을 기획하고 개선합니다. 도메인으로는 전시, 검색, 회원, 리뷰, 라이브, 신규 비즈니스 발굴을 맡고 있습니다.",
    ],
    tags: ["고객니즈", "문제해결", "사용자경험개선"],
  },
  {
    type: TEAM.PRODUCT,
    title: "O2O플랫폼",
    icon: "o2o.png",
    description: [
      "O2O플랫폼은 옴니채널 관점의 다양한 O2O 서비스의 발굴과 사업화를 추진하고, 온-오프라인 비즈니스 운영을 위한 백오피스 시스템의 프로세스 기획 및 개선활동을 수행합니다.",
      "올리브영 백오피스 시스템의 상품과 프로모션, 매장/영업, 정산 서비스의 기획 및 개선활동을 담당합니다.",
    ],
    tags: ["옴니채널", "백오피스", "O2O 서비스"],
  },
  {
    type: TEAM.ECOMMERCE,
    title: "퍼포먼스마케팅",
    icon: "op_perfomance.png",
    description: [
      "올리브영 온라인몰 방문 유저의 유입 채널과 전환을 분석하고,e커머스 성장을 위한 유저 확대 계획을 수립합니다. 디지털 매체 광고를 통해 유입된 고객의 구매 전환 과정을 분석해 광고 집행 전략을 수립하고 수행합니다. 실패를 두려워하지 않고 더 좋은 결과를 얻기 위해 계속해서 Test & Learn을 지속하는 끈기가 필요합니다.",
    ],
    tags: ["방문 유저 확대", "데이터 트래킹", "전환 효율"],
  },
  {
    type: TEAM.ECOMMERCE,
    title: "온사이트마케팅",
    icon: "op_onsite.png",
    description: [
      "올리브영 온라인몰 방문 유저의 구매 전환을 촉진하는 세일즈 프로모션과 서비스 활성화 마케팅 캠페인을 기획합니다. 쿠폰, 가격할인, 경품, 증정, 고객 참여 이벤트 등 다양한 마케팅 기법을 최적화하여 기획합니다. 여러 유관부서와 협업을 추진하는 커뮤니케이션이 중요하며, 트렌드 변화에 민감하고 항상 새로운 기획에 도전하는 창의성이 중요합니다.",
    ],
    tags: ["마케팅 캠페인", "세일즈 프로모션", "구매 전환"],
  },
  {
    type: TEAM.ECOMMERCE,
    title: "유저그로스",
    icon: "user_growth.png",
    description: [
      "올리브영 온라인몰의 서비스와 회원의 지속적인 성장을 위해 지표를 수립하고 개선점을 찾아내는 분석을 진행합니다. 서비스에 대한 인사이트를 유관부서에 제공하고, 회원에 대한 인사이트를 바탕으로 개인화 CRM을 직접 수행합니다.",
    ],
    tags: ["Growth", "분석", "개인화 CRM"],
  },
  {
    type: TEAM.DESIGN,
    title: "UI/UX디자인",
    subtitle: "UI/UX 디자인",
    icon: "ui_ux_design.png",
    description: [
      "사용자가 원하는 것을 바탕으로 온라인몰의 UX를 설계합니다. 고객 여정 맵, 유저 리서치, 데이터를 통해 도출된 문제점을 정성, 정량적으로 수집하고 개선하여 유저들에게 제품의 가치를 전달합니다. 고객과 일관성 있게 소통할 수 있는 디자인 환경을 구축하고 지속적인 변화에 대응 및 발전할 수 있도록 디자인 시스템을 설계하여 디자인 방향성을 도출합니다.",
    ],
    tags: ["UX", "사용자 경험 개선", "가치전달"],
  },
  {
    type: TEAM.DESIGN,
    title: "UI/UX디자인",
    subtitle: "커머스 디자인",
    icon: "ui_ux_commerce.png",
    description: [
      "올리브영 온라인몰 비주얼 디자인 원칙 및 시스템을 구축하여 그래픽 아이덴티티를 확립하고 통일된 톤 앤 매너를 기반으로 마케팅 프로모션, 에디토리얼 콘텐츠 등 다양한 그래픽을 주도적으로 디자인합니다. 온라인몰 콘텐츠가 어디서든 일관성 있게 소통할 수 있도록 디자인 가이드를 제작하고 그래픽을 고도화하여 고객이 콘텐츠를 접하는 경험을 개선합니다.",
    ],
    tags: ["Visual Identity", "Graphic Design", "Creative"],
  },
  {
    type: TEAM.ECOMMERCE,
    title: "미디어커머스",
    icon: "v_commerce.png",
    description: [
      "라이브 커머스& 영상콘텐츠 기획과 운영을 하는 팀 라이브 방송을 통하여 고객과 실시간 소통을 하고 고객에게 좋은 혜택을 소개하는 라이브 방송 업무를 하고 있으며, 영상콘텐츠 제작을 하여 올리브영의 고객에게 더욱 즐거움을 드리고자 노력하고 올리브영의 가치를 전달하고 소통하는 업무를 하고 있습니다.",
    ],
    tags: ["Live", "Youtube", "Communication"],
  },
  {
    type: TEAM.DATA,
    title: "데이터플랫폼",
    icon: "data.png",
    description: [
      "올리브영의 데이터 흐름을 맡고 있는 데이터 플랫폼팀입니다. 올리브영의 구성원들이 필요로 하는 다양한 데이터를 수집(파이프라인)하고, 적재/가공하여 데이터가 적재적소에서 쉽게 활용될 수 있도록 데이터 사용자들을 도와줍니다. 이를 통해 올리브영이 고객에 대한 이해를 바탕으로 데이터를 통한 비즈니스 성장을 견인하는 역할을 담당하고 있습니다.",
    ],
    tags: ["데이터", "빅데이터 플랫폼", "데이터 파이프라인"],
  },
  {
    type: TEAM.DATA,
    title: "AI추천엔진",
    icon: "ai.png",
    description: [
      "AI추천엔진팀은 올리브영 초개인화 맞춤 추천 서비스를 실현합니다. '핵심정보'와 '가치'를 지닌 빅데이터에 기반한 추천을 만들고, AI를 포함하여 급속도로 발전하고 있는 다양한 모델을 적용하여 추천 알고리즘을 개발하며, 올리브영 초개인화 맞춤 추천 서비스를 실현하기 위해 온·오프라인 통합 추천 서비스를 만들어 나갑니다.",
    ],
    tags: ["빅데이터", "AI", "추천알고리즘"],
  },
  {
    type: TEAM.STAFF,
    title: "디지털기획",
    subtitle: "서비스전략",
    icon: "digital_service.png",
    description: [
      "디지털기획팀 서비스전략파트는 올리브영의 디지털 진화 및 성장을 위한 신규 디지털 사업과 서비스에 대해 고민합니다. 다양한 사례들과 마켓 센싱을 통해 아이디어를 얻고, 트렌디한 테크 시장의 변화를 따라가며 중장기적인 먹거리를 찾아 올리브영만의 새로운 사업과 서비스를 도출, 기획합니다.",
    ],
    tags: ["New Biz.", "New Category", "New Service"],
  },
  {
    type: TEAM.STAFF,
    title: "디지털기획",
    subtitle: "경영관리",
    icon: "digital_finance.png",
    description: [
      "디지털기획팀 경영관리파트는 올리브영 디지털 사업의 경영계획을 수립하고 정기적으로 주요지표들을 관리, 경영성과를 진단하며 사업이 잘 순항하고 있는지를 확인합니다. 또 실적 분석을 통해 정량적, 정성적 인사이트를 도출, 올리브영 디지털 사업이 개선해야 할 부분을 찾아내고 방향성을 점검합니다.",
    ],
    tags: ["Planning", "Finance", "KPI"],
  },

  {
    type: TEAM.STAFF,
    title: "PMO",
    subtitle: "PMO전략",
    icon: "pmo_pmo.png",
    description: [
      "디지털 전략 과제들이 효율적으로 수행될 수 있도록 프로덕트 로드맵을 수립하고 관리하는 파트입니다. 비즈니스적 우선순위와 프로덕트 방향성이 일치하는지 진단하고, 리소스 분배가 적절히 되고 있는지 리포팅하며, 과제 진행 과정에서 발생하는 복잡한 이해관계자와의 커뮤니케이션을 중재 하는 역할을 합니다.",
    ],
    tags: ["우선순위", "로드맵관리", "협업"],
  },

  {
    type: TEAM.STAFF,
    title: "PMO",
    subtitle: "IT전략",
    icon: "pmo_it.png",
    description: [
      "올리브영 전사 구성원의 업무 환경 선진화를 위해 공통 IT시스템 전략 수립과 운영, SaaS 도입과 변화 관리, IT 기기 및 SW를 관리하는 파트입니다. IT 서비스 정책을 수립하고 전사 거버넌스를 통합하며, IT 투자/비용 예산 수립과 비용을 집행하는 역할을 합니다.",
    ],
    tags: ["IT시스템전략", "변화관리", "IT자산관리"],
  },

  {
    type: TEAM.STAFF,
    title: "TPM",
    icon: "tpm.png",
    description: [
      "고객중심 사고를 바탕으로 Business Agility 가속화를 위해 일하는 방식을 수립하고 운영합니다. 일하는 방식이 실행되고 정착되도록 조직을 코칭합니다. 또한 구성원들과 함께 변화 관리를 통해 일하는 방식을 지속적으로 개선합니다.",
    ],
    tags: ["일하는방식", "조직코칭", "변화관리"],
  },

  {
    type: TEAM.TECH,
    title: "디스커버리 개발",
    icon: "discovery.png",
    description: [
      "디스커버리 개발팀은 e-commerce 고객 여정에서 인지(Awareness) 영역 개발을 담당하고 있습니다. 고객이 상품을 탐색하고 발견해 구매로 이어질 수 있도록 더 나은 고객 경험을 시각적으로 개선할 수 있도록 합니다. 또한, 고객 이탈을 방지하기 위해 성능적으로 뛰어난 기능을 제공하기 위해 노력합니다.",
    ],
    tags: ["Home & Displaying", "Search", "Review"],
  },
  {
    type: TEAM.TECH,
    title: "PE",
    icon: "platform_tech.png",
    description: [
      "플랫폼 엔지니어링팀은 온라인몰의 인프라와 품질을 책임집니다. SRE(DevOps)는 서버와 클라우드 인프라를 구축과 운영, CI/CD, 모니터링 시스템을 구축합니다. DBA는 서비스의 생명과도 같은 DBMS의 안정적 운영과 높은 성능을 높이기 위해 노력합니다. 품질을 담당하는 QA는 새롭게 개발된 서비스에 오류가 없는지 검증하여 더 좋은 서비스로 우리 고객들이 불편 없이 서비스를 사용할 수 있도록 합니다.",
    ],
    tags: ["SRE(DevOps)", "DBMS", "QA"],
  },
  {
    type: TEAM.TECH,
    title: "리테일플랫폼",
    icon: "core.png",
    description: [
      "리테일플랫폼팀은 온/오프 커머스 백엔드 관점에서의 개발/운영을 담당하고 있으며 올리브영 백오피스 시스템의 상품과 프로모션, 매장/영업, 정산 서비스의 개발 및 개선활동을 담당합니다. 파트너/브랜드를 위한 인사이트 제공 및 업무 효율화를 위한 서비스를 제공합니다. SCM 서비스를 운영하며 매장 및 판매재고를 관리 운영합니다.",
    ],
    tags: ["커머스 백엔드(매장, 매출, 재고)", "SCM", "Partner"],
  },
  {
    type: TEAM.TECH,
    title: "콘텐츠서비스개발",
    icon: "contents_tech.png",
    description: [
      "콘텐츠서비스개발팀은 회원, 리뷰, 매거진, 라이브, Wcare를 담당하고 있습니다. 사용자들에게 원활하고 편리한 서비스를 제공하기 위해 끊임없이 기술적인 발전과 서비스 개선을 추구하며, 최신 기술과 트렌드에 대한 지속적인 연구와 개발을 진행하고 있습니다.",
    ],
    tags: ["회원", "리뷰", "매거진", "라이브", "Wcare"],
  },
  {
    type: TEAM.TECH,
    title: "커머스서비스개발",
    icon: "commerce_tech.png",
    description: [
      "커머스서비스개발팀은 온라인몰의 전시, 검색, 프로모션의 담당하고 있습니다. 고객 가치 및 높은 책임감과 주인의식, 열정을 가지고 운영하고 있으며 온라인몰의 최전방에서 사용자들이 상품이나 프로모션 등을 편리하게 볼 수 있도록 개발하는 역할을 하고 있습니다, 기존 레거시 코드를 걷어내는 작업과 많은 트래픽을 효율적으로 관리하는 방법들에 대해서 고민하고 있습니다.",
    ],
    tags: ["전시", "검색", "프로모션"],
  },
  {
    type: TEAM.TECH,
    title: "모바일앱개발",
    icon: "mobile_tech.png",
    description: [
      "모바일앱개발팀은 올리브영 Android/iOS 앱 네이티브 영역을 담당하고 있습니다. 고객 중심적 사고를 갖고 개선점을 찾는 것을 최우선 목표로 하고 있으며, 앱 성능 개선을 위해 점진적으로 네이티브 요소를 도입하여 개선하고 있습니다.",
    ],
    tags: ["iOS", "Android"],
  },
  {
    type: TEAM.TECH,
    title: "트랜잭션 개발",
    icon: "transaction.png",
    description: [
      "온라인몰에서 장바구니->주문->배송->정산 영역을 담당하고 있습니다. 온라인몰의 가장 핵심적인 기능이면서 올리브영에서는 다른 이커머스에서 잘하지 못하고 있는 오프라인 매장과의 밀접한 연동을 통한 옴니채널 서비스 부분에서도 매장과의 재고 연동, 주문 연동 등 가장 핵심적인 부분을 담당하고 있습니다.",
    ],
    tags: ["주문", "재고", "정산"],
  },
];

const TeamItem = ({
  icon,
  title,
  subtitle,
  description,
  tags,
}: TeamCardType) => {
  return (
    <div className={styles.card}>
      <div>
        <img
          src={`/assets/icon/ic_team_${icon}`}
          className={styles.icon}
          alt={`올리브영 테크 블로그 팀 소개 ${title}`}
        />
        <div className={styles.title}>
          <p className={styles.main}>{title}</p>
          <p className={styles.sub}>{subtitle}</p>
        </div>
        <div className={styles.description}>
          {description?.map((list) => (
            <p
              className={styles.item}
              key={`올리브영 디지털사업본부 팀 ${title} 소개 ${list}`}
            >
              {list}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.tag}>
        {tags?.map((tag) => (
          <span
            className={styles.item}
            key={`올리브영 디지털사업본부 팀 ${title} 소개 태그 ${tag}`}
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

const TeamCard = () => {
  const [category, setCategory] = useState<CategoryType[]>([
    { title: "전체", type: TEAM.ALL, isActive: true },
    { title: "테크", type: TEAM.TECH, isActive: false },
    { title: "데이터/AI", type: TEAM.DATA, isActive: false },
    { title: "프로덕트", type: TEAM.PRODUCT, isActive: false },
    { title: "디자인", type: TEAM.DESIGN, isActive: false },
    { title: "e커머스", type: TEAM.ECOMMERCE, isActive: false },
    { title: "스태프", type: TEAM.STAFF, isActive: false },
  ]);

  const [teamData, setTeamData] = useState(team);

  const handleCategoryClick = (type: number) => {
    setCategory(
      category.map((cate) =>
        cate.type === type
          ? { ...cate, isActive: true }
          : { ...cate, isActive: false }
      )
    );

    setTeamData(
      type === TEAM.ALL ? team : team.filter((data) => data.type === type)
    );
  };

  return (
    <section>
      {category.map((item) => (
        <Category
          key={`welfare_cate_${item.title}`}
          title={item.title}
          type={item.type}
          isActive={item.isActive}
          onCategoryClick={handleCategoryClick}
        />
      ))}
      <div className={styles.container}>
        {teamData?.map((item) => (
          <TeamItem
            key={`올리브영 디지털사업본부 팀 ${item.title} ${item.subtitle}`}
            {...item}
          />
        ))}
      </div>
    </section>
  );
};

export default TeamCard;

import React from "react";

import Wrapper from "@components/Wrapper";
import CardView from "@components/View/CardView";
import PageView from "@components/View/PageView";

import IntroduceCard from "./components/IntroduceCard";
import TeamCard from "./components/TeamCard";

const IntroduceModule = () => (
  <Wrapper>
    <PageView title="올리브영 Tech, 조직을 소개합니다.">
      <CardView title="조직 소개">
        <IntroduceCard />
      </CardView>
      <CardView title="우리가 하는 일">
        <TeamCard />
      </CardView>
    </PageView>
  </Wrapper>
);

export default IntroduceModule;
